<template>
  <ValidationObserver ref="observe">
    <b-form>
      <ek-dialog
        ref="addDialog"
        title="إضافة مادة"
        size="md"
        placeholder="ابحث عن مادة محدد"
        btnText="اضافة مادة"
        @ok="submit()"
        @close="reset"
        @search="setSearch"
      >
        <template #body>
          <ek-input-text
            :rules="[{ type: 'required', message: '  اسم المادة مطلوب' }]"
            name="name"
            placeholder=" ادخل اسم المادة"
            label="  اسم المادة"
            v-model="subjectDto.name"
          ></ek-input-text>

          <ek-input-select
            :rules="[{ type: 'required', message: '  يرجى اختيار الصف' }]"
            :options="classes"
            name="classes"
            placeholder="اختر الصف "
            label=" الصف"
            v-model="subjectDto.classId"
          ></ek-input-select>
          <ek-input-select
            :options="[
              { name: 'مخصص', value: ['', ''] },
              ...colorList.map((item, index) => ({
                name: `${item}`,
                value: item,
              })),
            ]"
            v-model="subjectDto.colors"
            placeholder="اختر او خصص لون معين"
            valueLabel="value"
            name=" اختر لون"
            label="اللون"
            :rules="[
              {
                type: 'required',
                message: 'اللون مطلوب',
              },
            ]"
          >
            <template #option="value">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="w-25 d-flex justify-content-between align-items-center">
                  <div
                    id="color"
                    :style="`background-color: ${value.label.split(',')[0]}`"
                  ></div>
                  <div
                    id="color"
                    :style="`background-color: ${value.label.split(',')[1]}`"
                  ></div>
                </div>
                <span> {{ value.label }}</span>
              </div>
            </template>
          </ek-input-select>
          <b-form-group
            label="اللون الاول"
            label-for="اللون"
            v-if="subjectDto.colors[0] == ''"
          >
            <b-form-input type="color" name="اللون" v-model="subjectDto.colors[0]" />
          </b-form-group>

          <b-form-group
            label="اللون الثاني"
            label-for="اللون"
            v-if="subjectDto.colors[1] == ''"
          >
            <b-form-input type="color" name="اللون" v-model="subjectDto.colors[1]" />
          </b-form-group>

          <ek-input-image
            ref="inputFile"
            label="ايقونة المادة"
            name="صور "
            @input="updateSubIcon($event)"
          >
            <h5>انقر لتحميل الايقونة</h5>
          </ek-input-image>

          <ek-input-image
            ref="imgFile"
            label=" صورة المادة "
            name="صور "
            @input="updateSubFile($event)"
          >
            <h5>انقر لتحميل الصورة</h5>
          </ek-input-image>
        </template>
      </ek-dialog>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapMutations } from "vuex";
import { BToast } from "bootstrap-vue";

export default {
  components: {
    ValidationObserver,
  },
  computed: {
    ...mapState({
      subjectDto: (state) => state.subjects.subjectDto,
      classes: (state) => state.classes.classesOptions,
      colorList: (state) => state.settings.settingDto.colors,
      educationLevelList: (state) => state.classes.educationLevelList,
    }),
  },
  methods: {
    ...mapActions(["addSubject"]),
    ...mapMutations(["Reset_Subjects_Dto"]),
    submit() {
      this.$refs.observe.validate().then((success) => {
        if (success && this.subjectDto.file && this.subjectDto.iconFile) {
          this.addSubject(this.subjectDto);
          this.$refs.addDialog.close();
          this.$refs.observe.reset();
        } else {
          const bToast = new BToast();
          bToast.$bvToast.toast("يرجى اختيار ملف", {
            title: "الملف  مطلوب",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }
      });
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name", "className", "dateCreated"],
        query,
      });
    },
    reset() {
      this.$refs.addDialog.close();
      this.$refs.observe.reset();
      if (this.$refs.inputFile) this.$refs.inputFile.reset();
      if (this.$refs.imgFile) this.$refs.imgFile.reset();
    },
    updateSubFile($event) {
      this.subjectDto.file = $event.file;
    },
    updateSubIcon($event) {
      this.subjectDto.iconFile = $event.file;
    },
  },
};
</script>

<style>
#color {
  width: 30px !important;
  height: 30px !important;
}
</style>
